import * as React from "react";

const ContactForm = () => (
  <div className="border border-gray-200 p-4 sm:p-8 xl:p-16 sm:text-lg">
    <h2 className="text-blue-800 text-2xl sm:text-4xl">Book a free consultation</h2>
    <form name="Contact" action="https://getform.io/f/bb20e2d9-4b7f-4c17-858e-53a49f54a9db" method="POST">
        <div>
            <div>
              <div className="mt-4 sm:mt-8 flex flex-col sm:flex-row gap-4 2xl:gap-8">
                <div className="w-full sm:w-1/2">
                  <label className="_label">
                    <p className="sm:mb-2">Name</p>
                    <input className="_input" type="text" name="Name" required />
                  </label>
                </div>
                <div className="w-full sm:w-1/2">
                  <label className="_label">
                    <p className="sm:mb-2">E-mail</p>
                    <input className="_input" type="email" name="Email" required />
                  </label>
                </div>
              </div>
              
              <div className="mt-4 sm:mt-8 flex flex-col sm:flex-row gap-4 2xl:gap-8">
                <div className="w-full sm:w-1/2">
                  <label className="_label">
                    <p className="sm:mb-2">Organisation</p>
                    <input className="_input" type="text" name="Organisation" required />
                  </label>
                </div>
                <div className="w-full sm:w-1/2">
                  <label className="_label">
                    <p className="sm:mb-2">
                      Phone <span className="text-gray-400 font-normal">(optional)</span>
                    </p>
                    <input className="_input" type="text" name="Phone" />
                  </label>
                </div>
              </div>

              <div className="mt-4 sm:mt-8">
                <div>
                  <label className="_label">
                    <p className="sm:mb-2">Message</p>
                    <textarea className="_input" name="Message" required></textarea>
                  </label>
                </div>
              </div>
              <div className="mt-4 sm:mt-8">
                <div>
                  <button type="submit" className="bg-blue-800 text-base sm:text-lg font-bold text-white py-3 px-6">
                    Submit
                  </button>
                </div>
              </div>
            </div>
        </div>
    </form>
  </div>
);

export default ContactForm;
